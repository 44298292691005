import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SecondsConvertorService {

  constructor() {
  }

  convert(given_seconds) {
    if (given_seconds && given_seconds > 0) {
      let hours = Math.floor(given_seconds / 3600);
      let minutes = Math.floor(given_seconds % 3600 / 60);
      let seconds = given_seconds % 60;
      return hours > 0
        ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return '00:00';
  }
}
